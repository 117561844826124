import './Caps.css';

function Caps() {
  return ( 
    <div className="Caps-header">

      <p> Decidir no es dividir, es exercir la llibertat individual i per extensió col·lectiva</p>
      
    </div>
   
  );

}

export default Caps;
