import uoct17 from './uoct17.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={uoct17} className="App-logo" alt="logo" /> 
        <br></br> <br></br>  
         SOM SOBIRANS.  <br></br>
         Aviat estarà enllestit.
         
        
      </header>
    </div>
  );
}

export default App;
